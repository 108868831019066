'use client'

import { cn } from '~/core/ui/utils'
import { ReactNode, forwardRef } from 'react'

interface ContainerProps {
  children?: ReactNode
  overrideClass?: string
  centerContent?: boolean
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ overrideClass = '', centerContent = false, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'mx-auto',
          overrideClass,
          centerContent ? 'flex flex-col items-center' : ''
        )}
        {...rest}
      />
    )
  }
)

Container.displayName = 'Container'

export { Container }
export type { ContainerProps }
