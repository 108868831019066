import { gql } from 'urql'

const QueryCompanyLocationOptions = gql`
  query (
    $companyId: Int
    $limit: Int
    $page: Int
    $search: String
    $jobId: Int
  ) {
    companyLocationsList(
      companyId: $companyId
      jobId: $jobId
      limit: $limit
      page: $page
      search: $search
    ) {
      collection {
        id
        address
        country
        state
        city
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryCompanyLocationOptions
