import { gql } from 'urql'

const QueryPipelineTemplates = gql`
  query ($limit: Int!, $page: Int!, $search: String) {
    pipelineTemplatesList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        name
        default
        createdAt
        updatedAt
        pipelineStages {
          id
          stageLabel
          stageGroup
          stageGroupDescription
          updateable
          stageType {
            id
          }
          index
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryPipelineTemplates
