import { gql } from 'urql'

const QueryJobCategories = gql`
  query ($limit: Int!, $page: Int!, $search: String) {
    jobCategoriesList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        name
        status
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryJobCategories
