'use client'

import { FormProperties } from '~/core/ui/Form'
import dynamic from 'next/dynamic'
import { ReactElement } from 'react'
import { FieldValues } from 'react-hook-form'

export const DynamicImportForm = dynamic(
  () => import('~/core/ui/Form').then((res) => res.Form),
  {
    ssr: false
  }
) as <TFieldValues extends FieldValues = FieldValues>(
  props: FormProperties<TFieldValues>
) => ReactElement
