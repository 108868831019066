import STATIC_DATA from '../../.hireforce/data/static_data.json'
import STATIC_DATA_JA from '../../.hireforce/data/static_data_ja.json'

type IStaticType =
  | 'stageTypes'
  | 'agency_stageTypes'
  | 'languages'
  | 'languageProficiencies'
  | 'degrees'

const useStaticData = ({
  keyName = 'stageTypes',
  locale = 'en'
}: {
  keyName: IStaticType
  locale?: string
}) => {
  const filter = (locale === 'en' ? STATIC_DATA : STATIC_DATA_JA || []).filter(
    (item) => item?.data?.[keyName]
  )?.[0]

  return (
    // @ts-expect-error
    filter?.data?.[keyName]?.collection || filter?.data?.[keyName] || []
  )
}

export default useStaticData
