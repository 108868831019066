import { useState } from 'react'
import configuration from '~/configuration'
import { IPromiseSearchOption } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import QueryTenantMembers from '../graphql/query-tenant-members'
import { IMember } from '../types'

const usePromiseOwnerOptions = (configParams?: {
  jobOwnable?: boolean
  requisitionApprovers?: boolean
  userReqPermisisons?: boolean
  avatarSize?: string
  disabledOption?: Array<string>
  tooltipDisableOption?: string
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const [filter, setFilter] = useState<{
    jobOwnable?: boolean
    recruiterIds?: number[]
    hiringMemberIds?: number[]
    avatarSize?: string
  }>({
    jobOwnable: true,
    recruiterIds: undefined,
    hiringMemberIds: undefined,
    avatarSize: undefined
  })

  const promiseMembersOwnerOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      return clientGraphQL
        .query(QueryTenantMembers, {
          ...params,
          ...(configParams ? configParams : filter)
        })
        .toPromise()
        .then((result: IResponseContextResult<IMember>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { tenantMembers } = result.data
          const collection = tenantMembers?.collection || []
          const metadata = tenantMembers?.metadata || {}

          const cloneData = collection.map((item: IMember) => {
            return {
              value: item.id,
              avatar: item.avatarVariants?.thumb?.url,
              avatarVariants: item.avatarVariants,
              ...(configParams?.avatarSize
                ? {
                    avatarSize: configParams?.avatarSize
                  }
                : filter?.avatarSize
                ? {
                    avatarSize: filter?.avatarSize
                  }
                : {}),
              ...(configParams?.tooltipDisableOption
                ? {
                    tooltipDisabled: configParams.tooltipDisableOption
                  }
                : {}),
              supportingObj: {
                name: item.fullName,
                description: item.email,
                defaultColour: item.defaultColour,
                helpName: item.roles?.[0]?.name
              },
              ...(configParams?.disabledOption && item.id
                ? {
                    disabled: configParams.disabledOption.includes(item.id)
                  }
                : {})
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })
  return { filter, setFilter, promiseMembersOwnerOptions }
}
export default usePromiseOwnerOptions
