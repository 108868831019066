import { gql } from 'urql'

const QuerySkillsList = gql<
  {
    skillsList: {
      collection: { id: number; name: string }[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query ($limit: Int!, $page: Int!, $search: String) {
    skillsList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        name
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QuerySkillsList
