import { gql } from 'urql'

const QueryPublicIndustries = gql<{
  publicIndustriesList: {
    collection: { id: string; name: string; status?: number }[]
    metadata: { totalCount: number }
  }
}>`
  query ($limit: Int!, $page: Int!, $search: String) {
    publicIndustriesList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        name
        status
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryPublicIndustries
