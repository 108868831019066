'use client'

import { cn } from '~/core/ui/utils'

const Divider = ({ className = '' }: { className?: string }) => {
  return <div className={cn('h-[1px] bg-gray-100', className)} />
}

Divider.displayName = 'Divider'

export { Divider }
