import { gql } from 'urql'

const QueryTalentPoolList = gql<
  {
    talentPoolsList: {
      collection: { id: number; name: string }[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query (
    $limit: Int
    $page: Int
    $search: String
    $status: TalentPoolStatus
    $sorting: JSON
    $employeeId: Int
  ) {
    talentPoolsList(
      limit: $limit
      page: $page
      search: $search
      status: $status
      sorting: $sorting
      employeeId: $employeeId
    ) {
      collection {
        id
        name
        createdBy {
          email
          fullName
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTalentPoolList
